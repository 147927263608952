export const ROOT_PATHNAME = '/';

// SET pathnames with the base url
export const ABOUT_US_PATHNAME = `${ROOT_PATHNAME}about-us`;
export const ACCOUNT_VERIFICATION_PATHNAME = `${ROOT_PATHNAME}account_verification`;
export const APPOINTMENT_PATHNAME = `${ROOT_PATHNAME}appointment`;
export const APPOINTMENT_RESCHEDULE_PATHNAME = `${ROOT_PATHNAME}reschedule-appointment`;
export const BUSINESS_PATHNAME = `${ROOT_PATHNAME}business`;
export const CONTACT_US_PATHNAME = `${ROOT_PATHNAME}contact-us`;
export const COVERAGE_PATHNAME = `${ROOT_PATHNAME}coverage`;
export const EMAIL_VERIFICATION_PATHNAME = `${ROOT_PATHNAME}email_verification`;
export const FAQ_PATHNAME = `${ROOT_PATHNAME}faq`;
export const FINALISE_CAMPAIGN_PATHNAME = `${ROOT_PATHNAME}finalise-campaign`;
export const FULL_FIBRE_PATHNAME = `${ROOT_PATHNAME}full-fibre`;
export const LEGAL_PATHNAME = `${ROOT_PATHNAME}legal`;
export const LOGIN_PATHNAME = `${ROOT_PATHNAME}login`;
export const NEWS_PATHNAME = `${ROOT_PATHNAME}news`;
export const NEWS_POST_PATHNAME = `${ROOT_PATHNAME}news-article`;
export const OFFERS_PATHNAME = `${ROOT_PATHNAME}offers`;
export const PASSWORD_RESET_PATHNAME = `${ROOT_PATHNAME}customer_password`;
export const PERSONAL_AREA_PATHNAME = `${ROOT_PATHNAME}personal-area`;
export const PERSONAL_PAYMENTS_PATHNAME = `${ROOT_PATHNAME}personal-area/payments`;
export const PERSONAL_PROFILE_PATHNAME = `${ROOT_PATHNAME}personal-area/profile`;
export const PERSONAL_SERVICES_PATHNAME = `${ROOT_PATHNAME}personal-area/services`;
export const REFER_FRIENDS_PATHNAME = `${ROOT_PATHNAME}refer-friends`;
export const REFER_FRIENDS_UNSUBSCRIBE_PATHNAME = `${ROOT_PATHNAME}unsubscribe-from-referral`;
export const REQUEST_PASSWORD_RESET_PATHNAME = `${ROOT_PATHNAME}request-password-reset`;
export const RESIDENTIAL_PATHNAME = `${ROOT_PATHNAME}residential`;
export const SERVICE_STATUS_PATHNAME = `${ROOT_PATHNAME}personal-area/service-status`;
export const SPEED_TEST_PATHNAME = `${ROOT_PATHNAME}personal-area/speed-test`;
export const UNAUTHORIZED_PATHNAME = `${ROOT_PATHNAME}unauthorized`;
