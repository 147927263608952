import React from 'react';

import { Button } from '@mui/material';

export default function LogoutButton(props) {
  const { logout } = props;

  return (
    <Button
      size="small"
      onClick={logout}
      sx={{ fontSize: '14px', color: 'grey.500', padding: '0.5em 0.8em' }}
    >
      LOGOUT
    </Button>
  );
}
