import { useEffect, Suspense } from 'react';
import { Outlet, useNavigate, useLoaderData, ScrollRestoration } from 'react-router-dom';

import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import Header from './Header';
import Footer from './Footer';
import theme from './theme.js';

import SpinnerOfDoom from '../components/SpinnerOfDoom';
import { CookieBanner } from '../components/CookieBanner';

import useStore from '../store';
import { logout, getProfile } from '../utils/authentication';

export default function Root() {
  const navigate = useNavigate();

  const config = useLoaderData();
  const { api, baseUrl, googleTmContainerId } = config;

  const profile = useStore((state) => state.profile);
  const updateProfile = useStore((state) => state.updateProfile);

  const isUserLoggedIn = profile?.role === 'customer';

  // Once the config is ready, initialize the profile
  useEffect(() => {
    if (api) initProfile(api);
  }, [api]);

  async function initProfile(apiUrl) {
    if (!profile) {
      const profile = await getProfile({ apiUrl });
      updateProfile(profile);
    }
  }

  // LOGOUT user
  function logoutUser() {
    logout(api);
    navigate(baseUrl);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollRestoration />

      <Header logout={isUserLoggedIn ? logoutUser : undefined} />

      <Suspense fallback={<SpinnerOfDoom />}>
        <Outlet context={{ ...config }} />
      </Suspense>

      <Footer />

      <CookieBanner googleTagManagerContainerId={googleTmContainerId} />
    </ThemeProvider>
  );
}
