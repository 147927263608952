import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Stack,
  SwipeableDrawer,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from '@mui/material';

import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import LogoutButton from './LogoutButton';
import PersonalAreaButton from './PersonalAreaButton';

import * as routes from '../../routes';
import useWindowSize from '../../hooks/useWindowSize';

import logo from '../../assets/logo-dark.svg';

export default function Header(props) {
  const { logout } = props;

  const navigate = useNavigate();
  const { width } = useWindowSize();

  // SET the local states
  const [drawerOpen, setDrawerOpen] = useState(false);

  // SET whether the user is logged in or not
  const isUserLoggedIn = logout !== undefined;

  // SET whether the screen is mobile or not
  const isMobile = width <= 850;

  // SET the links to show
  const links = [
    { title: 'FULL FIBRE', pathname: routes.FULL_FIBRE_PATHNAME, loggedIn: false },
    { title: 'RESIDENTIAL', pathname: routes.RESIDENTIAL_PATHNAME, loggedIn: false },
    { title: 'BUSINESS', pathname: routes.BUSINESS_PATHNAME, loggedIn: false },
    { title: 'PACKAGES', pathname: routes.OFFERS_PATHNAME, loggedIn: false },
    { title: 'CHECK MY AVAILABILITY', pathname: routes.COVERAGE_PATHNAME, loggedIn: true },
    { title: 'FAQ', pathname: routes.FAQ_PATHNAME, loggedIn: true },
    { title: 'NEWS', pathname: routes.NEWS_PATHNAME, loggedIn: true },
    { title: 'ABOUT US', pathname: routes.ABOUT_US_PATHNAME, loggedIn: false },
  ];

  // OPEN and CLOSE the drawer
  function openDrawer() {
    setDrawerOpen(true);
  }
  function closeDrawer() {
    setDrawerOpen(false);
  }

  // NAVIGATE to the selected page
  function goToPage(pathname) {
    navigate(pathname);
    setDrawerOpen(false);
  }

  return (
    <AppBar sx={{ height: '66px', background: '#ffffffd9', boxShadow: 'none' }}>
      <Toolbar disableGutters sx={{ px: 2 }} spacing={2}>
        <Link to={routes.ROOT_PATHNAME}>
          <img src={logo} alt="logo" width="85px" height="58px" style={{ paddingTop: '7px' }} />
        </Link>

        <Box sx={{ flexGrow: 1 }} />

        {!isMobile && (
          <>
            <Stack spacing={3} direction="row" alignItems="center">
              {links
                .filter(
                  (link) =>
                    // FILTER the links to show when users are logged in
                    (isUserLoggedIn && link.loggedIn) ||
                    // NOT FILTER links when no user is logged in
                    !isUserLoggedIn,
                )
                .map(({ title, pathname }) => (
                  <Typography
                    key={pathname}
                    fontSize="14px"
                    component={Link}
                    to={pathname}
                    sx={{ textAlign: 'center', textDecoration: 'none' }}
                  >
                    {title}
                  </Typography>
                ))}

              {
                // SHOW the
                !logout && (
                  <IconButton component={Link} to={routes.LOGIN_PATHNAME}>
                    <AccountCircleIcon color="primary" />
                  </IconButton>
                )
              }
            </Stack>

            {isUserLoggedIn && (
              <Stack spacing={0} direction="row" alignItems="center" ml={2}>
                <PersonalAreaButton />
                <LogoutButton logout={logout} />
              </Stack>
            )}
          </>
        )}

        {isMobile && (
          <IconButton onClick={openDrawer}>
            <MenuOutlinedIcon color="primary" sx={{ fontSize: 32 }} />
          </IconButton>
        )}
      </Toolbar>

      <SwipeableDrawer anchor="right" open={drawerOpen} onOpen={openDrawer} onClose={closeDrawer}>
        <List>
          {links.map(({ title, pathname }) => (
            <ListItem key={pathname} disablePadding>
              <ListItemButton onClick={() => goToPage(pathname)}>
                <ListItemText>
                  <Typography fontSize="14px">{title}</Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}

          <Divider />

          <ListItem>
            <PersonalAreaButton />
          </ListItem>

          <ListItem>
            <LogoutButton logout={logout} />
          </ListItem>
        </List>
      </SwipeableDrawer>
    </AppBar>
  );
}
