import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import * as routes from '../../routes';

import logo from '../../assets/logo-dark.svg';

export default function Header() {
  const firstColumn = [
    { title: 'FULL FIBRE', pathname: routes.FULL_FIBRE_PATHNAME },
    { title: 'RESIDENTIAL', pathname: routes.RESIDENTIAL_PATHNAME },
    { title: 'BUSINESS', pathname: routes.BUSINESS_PATHNAME },
    { title: 'CHECK MY AVAILABILITY', pathname: routes.COVERAGE_PATHNAME },
  ];

  const secondColumn = [
    { title: 'FAQ', pathname: routes.FAQ_PATHNAME },
    { title: 'LEGAL', pathname: routes.LEGAL_PATHNAME },
    { title: 'CONTACT US', pathname: routes.CONTACT_US_PATHNAME },
    { title: 'ABOUT US', pathname: routes.ABOUT_US_PATHNAME },
  ];

  const thirdColumn = [
    {
      title: 'INSTAGRAM',
      pathname: 'https://www.instagram.com/lilaconnectuk/?hl=en-gb',
    },
    {
      title: 'FACEBOOK',
      pathname: 'https://www.facebook.com/LilaConnectUK/',
    },
    {
      title: 'TWITTER',
      pathname: 'https://twitter.com/LilaConnectUK',
    },
    {
      title: 'LINKEDIN',
      pathname: 'https://www.linkedin.com/company/lilaconnectuk/?viewAsMember=true',
    },
  ];

  return (
    <Box px={8} py={8} display="flex">
      <Box flexGrow={1}>
        {firstColumn.map(({ title, pathname }) => (
          <Box key={pathname} pb={1}>
            <Typography
              to={pathname}
              fontSize="14px"
              color="primary"
              component={Link}
              sx={{ textDecoration: 'none' }}
            >
              {title}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box flexGrow={1}>
        {secondColumn.map(({ title, pathname }) => (
          <Box key={pathname} pb={1}>
            <Typography
              to={pathname}
              fontSize="14px"
              color="primary"
              component={Link}
              sx={{ textDecoration: 'none' }}
            >
              {title}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box flexGrow={1}>
        {thirdColumn.map(({ title, pathname }) => (
          <Box key={pathname} pb={1}>
            <Typography
              to={pathname}
              fontSize="14px"
              color="primary"
              component={Link}
              sx={{ textDecoration: 'none' }}
            >
              {title}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box flexGrow={1} textAlign="right">
        <img src={logo} alt="logo" width="85px" height="58px" style={{ paddingTop: '7px' }} />

        <Typography color="primary" mt={2} fontSize="14px">
          © {new Date().getFullYear()} LilaConnect Ltd, ALL RIGHT RESERVED.
        </Typography>
      </Box>
    </Box>
  );
}
