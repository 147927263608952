import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useStore = create()(
  // PERSIST the store in the 'app-store' key of the localStorage
  persist(
    (set) => {
      const initialState = {
        loading: false,
        profile: undefined,
        // Camunda related
        businessKey: undefined,
        definitionKey: undefined,
      };

      const actions = {
        updateLoading: (loading) => set({ loading }),

        logoutProfile: () => set({ profile: undefined }),
        updateProfile: (profile) => {
          const {
            api_key: apiKey,
            client_ip: clientIp,
            last_name: lastName,
            first_name: firstName,
            old_api_key: oldApiKey,
            object_groups: objectGroups,
            customer_type: customerType,
            customer_number: customerNumber,
            autologin_object: autologinObject,
            ...rest
          } = profile;

          set({
            profile: {
              ...rest,
              apiKey,
              clientIp,
              lastName,
              firstName,
              oldApiKey,
              objectGroups,
              customerType,
              customerNumber,
              autologinObject,
            },
          });
        },

        // Camunda related
        updateBusinessKey: (businessKey) => set({ businessKey }),
        updateDefinitionKey: (definitionKey) => set({ definitionKey }),
      };

      return { ...initialState, ...actions };
    },
    { name: 'app-store' },
  ),
);

export default useStore;
