import * as Sentry from '@sentry/browser';

// ROOT
export async function rootLoader({ request }) {
  const res = await fetch('/config.json', { cache: 'no-cache', signal: request.signal });
  const config = await res.json();

  // START Sentry only in production mode
  if (process.env.NODE_ENV !== 'development') {
    if (config?.api) {
      Sentry.init({
        tracesSampleRate: 1.0,
        dsn: window.dsn,
        release: process.env.REACT_APP_RELEASE,
        environment: window.environment,
        integrations: [new Sentry.browserTracingIntegration()],
      });
    }
  }

  return config;
}

// GENERIC PAGE CONTENT
export function pageContentLoader(page) {
  return async function ({ request }) {
    const res = await fetch(`/content/${page}.json`, {
      cache: 'force-cache',
      signal: request.signal,
    });

    return await res.json();
  };
}

// LEGAL DOCUMENT
export async function legalDocLoader({ params, request }) {
  const res = await fetch(`/content/policies/${params.doc}.json`, {
    cache: 'force-cache',
    signal: request.signal,
  });

  return await res.json();
}
