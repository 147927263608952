import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Backdrop, Box, Button, Container, Typography, Stack, Switch } from '@mui/material';

import { LEGAL_PATHNAME } from '../../routes';

const injectGAScript = (googleTagManagerContainerId) => {
  // STORE the consent only if is not previously given
  if (!localStorage.getItem('consent')) {
    // TODO for GDPR compliance consent should be stored into DB
    // with an incremental logic 'cause it should be denied and given
    // multiple times from the same user/anonymous and legals has to be able to know exactly when
    localStorage.setItem('consent', `Given on ${new Date().toLocaleString()}`);
  }

  // CREATE th GTM HEAD script
  const GTMHeadScript = document.createElement('script');

  /* eslint-disable quotes */
  GTMHeadScript.innerText =
    `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':` +
    `new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],` +
    `j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=` +
    `'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);` +
    `})(window,document,'script','dataLayer','${googleTagManagerContainerId}');`;
  /* eslint-enable quotes */

  // CREATE the GTM BODY script elements
  const GTMBodyScript = document.createElement('noscript');
  const GTMiFrame = document.createElement('iframe');

  const iFrameWidth = document.createAttribute('width');
  const iFrameHeight = document.createAttribute('height');
  iFrameWidth.value = '0';
  iFrameHeight.value = '0';

  // APPLY attributes to the <iframe>
  GTMiFrame.src = `https://www.googletagmanager.com/ns.html?id=${googleTagManagerContainerId}`;
  GTMiFrame.setAttributeNode(iFrameWidth);
  GTMiFrame.setAttributeNode(iFrameHeight);
  GTMiFrame.style.display = 'none';
  GTMiFrame.style.visibility = 'hidden';

  // APPEND the <iframe> into the <noscript> element
  GTMBodyScript.appendChild(GTMiFrame);

  // APPEND scripts to the DOM
  document.head.appendChild(GTMHeadScript);
  document.body.appendChild(GTMBodyScript);
};

function CookieBanner(props) {
  const { googleTagManagerContainerId } = props;

  const COOKIE_POLICY_PATHNAME = `${LEGAL_PATHNAME}/cookie-policy`;

  // GET the current location
  const location = useLocation();
  const currentLocation = location.pathname;

  const consent = localStorage.getItem('consent');

  // SET the local states
  const [gtmCookie, setGtmCookie] = useState();
  const [functionalChecked, setFunctionalChecked] = useState(false);
  const [statisticalChecked, setStatisticalChecked] = useState(false);

  useEffect(() => {
    // Add Google Tag Manager scripts if consent was previously given
    if (consent) {
      injectScript();
    } else {
      setGtmCookie(false);
    }
  }, []);

  const injectScript = () => {
    injectGAScript(googleTagManagerContainerId);

    setGtmCookie(true);
    setFunctionalChecked(true);
    setStatisticalChecked(true);
  };

  const handleFunctionalCheckbox = () => {
    setFunctionalChecked(!functionalChecked);
  };

  const handleStatisticalCheckbox = () => {
    setStatisticalChecked(!statisticalChecked);
  };

  const closeBanner = () => {
    setGtmCookie(true);
  };

  // HIDE the cookie banner if:
  if (
    // 1. the consent has been given
    consent ||
    // 2. or users are on the legal pages
    currentLocation === LEGAL_PATHNAME ||
    // 3. or users are on the cookie-policy page
    currentLocation === COOKIE_POLICY_PATHNAME
  ) {
    return null;
  }

  return (
    <Backdrop open={!gtmCookie} sx={{ zIndex: 10000, background: '#000000DD' }}>
      <Container maxWidth="xs">
        <Box color="white">
          <Typography variant="h3">Cookies at LilaConnect</Typography>

          <Box mb={3} lineHeight="20px" textAlign="justify">
            We use cookies to navigate between pages efficiently, remembering your preferences, and
            generally improve the user experience. By clicking OK you consent to LilaConnect placing
            cookies on your device. Further information about the types of cookies LilaConnect uses
            can be found in our{' '}
            <Link
              to={COOKIE_POLICY_PATHNAME}
              style={{ color: 'white', textDecoration: 'underline' }}
            >
              Cookie Policy
            </Link>
            .
          </Box>

          <Box display="flex">
            <Box style={{ textAlign: 'left' }}>
              <label htmlFor="functionalToggle">Functional</label>
            </Box>

            <Box flexGrow={1} />

            <Box style={{ textAlign: 'right' }}>
              <Switch
                id="functionalToggle"
                toggle
                checked={functionalChecked}
                onClick={handleFunctionalCheckbox}
              />
            </Box>
          </Box>

          <Box display="flex">
            <Box style={{ textAlign: 'left' }}>
              <label htmlFor="statisticalToggle">Statistical</label>
            </Box>

            <Box flexGrow={1} />

            <Box style={{ textAlign: 'right' }}>
              <Switch
                id={'statisticalToggle'}
                toggle
                checked={statisticalChecked}
                onClick={handleStatisticalCheckbox}
              />
            </Box>
          </Box>

          <Stack spacing={2} direction="column" mt={3}>
            {functionalChecked || statisticalChecked ? (
              <Button
                variant="contained"
                sx={{ color: 'grey.700', backgroundColor: 'grey.300' }}
                onClick={functionalChecked && !statisticalChecked ? closeBanner : injectScript}
              >
                Proceed with your own settings
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{ color: 'grey.700', backgroundColor: 'grey.300' }}
                onClick={closeBanner}
              >
                Proceed with necessary cookies only
              </Button>
            )}

            <Button className="gradient" onClick={injectScript}>
              Accept recommended cookies
            </Button>
          </Stack>
        </Box>
      </Container>
    </Backdrop>
  );
}

export { CookieBanner, injectGAScript };
