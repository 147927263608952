import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { PERSONAL_AREA_PATHNAME } from '../../routes';

export default function PersonalAreaButton() {
  return (
    <Button
      size="small"
      component={Link}
      to={PERSONAL_AREA_PATHNAME}
      startIcon={<AccountCircleIcon />}
      sx={{ fontSize: '14px', padding: '0.5em 1em' }}
    >
      PERSONAL AREA
    </Button>
  );
}
