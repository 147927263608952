import { createTheme } from '@mui/material/styles';
import { lighten } from '@mui/system/colorManipulator';

// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import GilroyLightWoff2 from './fonts/Gilroy-Light/font.woff2';
import GilroyRegularWoff2 from './fonts/Gilroy-Regular/font.woff2';
import GilroyMediumWoff2 from './fonts/Gilroy-Medium/font.woff2';
import GilroyExtraBoldWoff2 from './fonts/Gilroy-ExtraBold/font.woff2';

import GilroyLightItalicWoff2 from './fonts/Gilroy-LightItalic/font.woff2';
import GilroyRegularItalicWoff2 from './fonts/Gilroy-RegularItalic/font.woff2';
import GilroyMediumItalicWoff2 from './fonts/Gilroy-MediumItalic/font.woff2';
import GilroyExtraBoldItalicWoff2 from './fonts/Gilroy-ExtraBoldItalic/font.woff2';

export default createTheme({
  palette: {
    primary: {
      main: '#6B4B99',
      light: '#D3B4FF1A',
    },
    secondary: {
      main: '#DA1974',
    },
    success: {
      main: '#21BA45',
    },
    grey: {
      100: '#F9FAFB',
      200: '#F4F6F8',
      300: '#DFE3E8',
      400: '#C4CDD5',
      500: '#919EAB',
      600: '#637381',
      700: '#454F5B',
      800: '#212B36',
      900: '#161C24',
    },
  },
  typography: {
    fontFamily: 'Gilroy, serif',
    h1: {
      fontSize: '4rem',
      fontWeight: 800,
      lineHeight: '3.75rem',
    },
    h2: {
      fontSize: '3rem',
      fontWeight: 800,
    },
    h3: {
      fontSize: '2.75rem',
      fontWeight: 800,
    },
    h4: {
      fontSize: '2.5rem',
      fontWeight: 800,
    },
    h5: {
      fontSize: '2rem',
      fontWeight: 800,
    },
    h6: {
      fontSize: '1.5rem',
      fontWeight: 800,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '1.438rem',
    },
  },
  shape: {
    borderRadius: 28,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: 'contained', color: 'success' },
              style: ({ theme }) => ({
                color: '#FFF',
                backgroundColor: theme.palette.success.main,
              }),
            },
            {
              props: { variant: 'contained', color: 'grey' },
              style: ({ theme }) => ({
                color: theme.palette.grey[700],
                backgroundColor: theme.palette.grey[300],
                '&:hover': {
                  color: theme.palette.grey[700],
                },
              }),
            },
          ],
        },
        sizeMedium: {
          height: '3em',
          fontSize: '14px',
          fontWeight: 800,
          padding: '0.8em 2em',
          letterSpacing: '1.5px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { color: 'info' },
              style: ({ theme }) => ({
                color: theme.palette.info.main,
                backgroundColor: lighten(theme.palette.info.light, 0.9),
              }),
            },
            {
              props: { color: 'success' },
              style: ({ theme }) => ({
                color: theme.palette.success.main,
                backgroundColor: lighten(theme.palette.success.light, 0.9),
              }),
            },
            {
              props: { color: 'warning' },
              style: ({ theme }) => ({
                color: theme.palette.warning.main,
                backgroundColor: lighten(theme.palette.warning.light, 0.9),
              }),
            },
            {
              props: { color: 'error' },
              style: ({ theme }) => ({
                color: theme.palette.error.main,
                backgroundColor: lighten(theme.palette.error.light, 0.9),
              }),
            },
          ],
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { severity: 'primary' },
              style: ({ theme }) => ({
                // icon: InfoOutlinedIcon,
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.light,
              }),
            },
          ],
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('Gilroy'), local('Gilroy-Light'), url(${GilroyLightWoff2}) format('woff2');
        }

        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Gilroy'), local('Gilroy-Regular'), url(${GilroyRegularWoff2}) format('woff2');
        }

        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Gilroy'), local('Gilroy-Light'), url(${GilroyMediumWoff2}) format('woff2');
        }

        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 800;
          src: local('Gilroy'), local('Gilroy-Light'), url(${GilroyExtraBoldWoff2}) format('woff2');
        }

        @font-face {
          font-family: 'Gilroy';
          font-style: italic;
          font-display: swap;
          font-weight: 300;
          src: local('Gilroy'), local('Gilroy-Light'), url(${GilroyLightItalicWoff2}) format('woff2');
        }

        @font-face {
          font-family: 'Gilroy';
          font-style: italic;
          font-display: swap;
          font-weight: 400;
          src: local('Gilroy'), local('Gilroy-Regular'), url(${GilroyRegularItalicWoff2}) format('woff2');
        }

        @font-face {
          font-family: 'Gilroy';
          font-style: italic;
          font-display: swap;
          font-weight: 500;
          src: local('Gilroy'), local('Gilroy-Light'), url(${GilroyMediumItalicWoff2}) format('woff2');
        }

        @font-face {
          font-family: 'Gilroy';
          font-style: italic;
          font-display: swap;
          font-weight: 800;
          src: local('Gilroy'), local('Gilroy-Light'), url(${GilroyExtraBoldItalicWoff2}) format('woff2');
        }
      `,
    },
  },
});
