import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Root from './Root';
import SpinnerOfDoom from './components/SpinnerOfDoom';

import * as routes from './routes';
import { rootLoader, legalDocLoader, pageContentLoader } from './loaders';

import './index.css';

const Page = lazy(() => import('./components/Page'));
const OldPage = lazy(() => import('./components/OldPage'));

const AccountVerificationNew = lazy(() => import('./pages/AccountVerificationNew'));
const Appointment = lazy(() => import('./pages/Appointment'));
const AppointmentReschedule = lazy(() => import('./pages/Appointment/Reschedule'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const Coverage = lazy(() => import('./pages/Coverage'));
const Faq = lazy(() => import('./pages/Faq'));
const FinaliseCampaign = lazy(() => import('./pages/FinaliseCampaign'));
const Legal = lazy(() => import('./pages/Legal'));
const LegalDoc = lazy(() => import('./pages/Legal/LegalDoc'));
const Login = lazy(() => import('./pages/Login'));
const News = lazy(() => import('./pages/News'));
const NewsPost = lazy(() => import('./pages/NewsPost'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Offers = lazy(() => import('./pages/Offers'));
const PasswordReset = lazy(() => import('./pages/PasswordReset'));
const PersonalArea = lazy(() => import('./pages/PersonalArea'));
const PersonalPayments = lazy(() => import('./pages/PersonalPayments'));
const PersonalProfile = lazy(() => import('./pages/PersonalProfile'));
const PersonalServices = lazy(() => import('./pages/PersonalServices'));
const ReferFriends = lazy(() => import('./pages/ReferFriends'));
const ReferFriendsUnsubscribe = lazy(() => import('./pages/ReferFriendsUnsubscribe'));
const RequestPasswordReset = lazy(() => import('./pages/RequestPasswordReset'));
const ServiceStatus = lazy(() => import('./pages/ServiceStatus'));
const SpeedTest = lazy(() => import('./pages/SpeedTest'));
const Unauthorized = lazy(() => import('./pages/Unauthorized'));

// CREATE ROUTER
const router = createBrowserRouter([
  {
    loader: rootLoader,
    element: <Root />,
    children: [
      {
        path: routes.ROOT_PATHNAME,
        element: <Page home />,
        loader: pageContentLoader('pages/home'),
      },
      {
        path: routes.ABOUT_US_PATHNAME,
        element: <Page />,
        loader: pageContentLoader('pages/about-us'),
      },
      {
        path: routes.FAQ_PATHNAME,
        element: <Faq />,
        loader: pageContentLoader('faqs'),
      },
      {
        path: routes.NEWS_PATHNAME,
        element: <News />,
      },
      {
        path: routes.NEWS_POST_PATHNAME,
        element: <NewsPost />,
      },
      {
        path: routes.LOGIN_PATHNAME,
        element: <Login />,
      },
      {
        path: routes.PASSWORD_RESET_PATHNAME,
        element: <PasswordReset />,
      },
      {
        path: routes.REQUEST_PASSWORD_RESET_PATHNAME,
        element: <RequestPasswordReset />,
      },
      {
        path: routes.EMAIL_VERIFICATION_PATHNAME,
        element: <PasswordReset />,
      },
      {
        path: routes.ACCOUNT_VERIFICATION_PATHNAME,
        element: <OldPage Component={AccountVerificationNew} />,
      },
      {
        path: routes.LEGAL_PATHNAME,
        element: <Legal />,
        children: [
          {
            path: ':doc',
            element: <LegalDoc />,
            loader: legalDocLoader,
          },
        ],
      },
      {
        path: routes.COVERAGE_PATHNAME,
        element: <Coverage />,
      },
      {
        path: routes.APPOINTMENT_PATHNAME,
        element: <Appointment />,
      },
      {
        path: routes.APPOINTMENT_RESCHEDULE_PATHNAME,
        element: <AppointmentReschedule />,
      },
      {
        path: routes.FINALISE_CAMPAIGN_PATHNAME,
        element: <OldPage Component={FinaliseCampaign} />,
      },
      {
        path: routes.SPEED_TEST_PATHNAME,
        element: <SpeedTest />,
      },
      {
        path: routes.BUSINESS_PATHNAME,
        element: <Page />,
        loader: pageContentLoader('pages/business'),
      },
      {
        path: routes.RESIDENTIAL_PATHNAME,
        element: <Page />,
        loader: pageContentLoader('pages/residential'),
      },
      {
        path: routes.FULL_FIBRE_PATHNAME,
        element: <Page />,
        loader: pageContentLoader('pages/full-fibre'),
      },
      {
        path: routes.OFFERS_PATHNAME,
        element: <Offers title="OFFERS" />,
        loader: pageContentLoader('pages/offers'),
      },
      {
        path: routes.CONTACT_US_PATHNAME,
        element: <ContactUs />,
      },
      {
        path: routes.REFER_FRIENDS_PATHNAME,
        element: <ReferFriends />,
      },
      {
        path: routes.REFER_FRIENDS_UNSUBSCRIBE_PATHNAME,
        element: <ReferFriendsUnsubscribe />,
      },
      {
        path: routes.PERSONAL_AREA_PATHNAME,
        element: <PersonalArea />,
      },
      {
        path: routes.PERSONAL_PROFILE_PATHNAME,
        element: <PersonalProfile />,
      },
      {
        path: routes.PERSONAL_PAYMENTS_PATHNAME,
        element: <PersonalPayments />,
      },
      {
        path: routes.PERSONAL_SERVICES_PATHNAME,
        element: <PersonalServices />,
      },
      {
        path: routes.SERVICE_STATUS_PATHNAME,
        element: <ServiceStatus />,
      },
      {
        path: routes.UNAUTHORIZED_PATHNAME,
        element: <Unauthorized />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);

// RENDER APP
createRoot(document.getElementById('root')).render(
  <RouterProvider router={router} fallbackElement={<SpinnerOfDoom />} />,
);
